
/*

 Textfields (with reset icon, adaptive placeholders etc)

 */

function textfield_init($o)
{
    if (typeof $o === "undefined") $o = $(".textfield");

    if (!$("body").hasClass("textfield-inited-globally")) {

        $(document).on("keydown keyup focus blur textfield-filled", ".textfield", function (e) {
            var is_filled = !!$(this).val();
            var $placeholder = $(this).nextAll(".textfield-placeholder").first();
            if (e.type === "textfield-filled") { // remove inline attribute value for CSS [value] selector purpose
                if ($(this).val().length) $(this).prop("value", $(this).val());
                $(this).removeAttr("value");
            }
            if ($placeholder.length && $placeholder.attr("data-label-short"))
            {
                if (e.type == "focusin" || e.type == "focusout")
                {
                    var label;
                    if (e.type == "focusin")
                    {
                        label = $placeholder.attr("data-label-short");
                        if (!$placeholder.attr("data-label-long"))
                        {
                            $placeholder.attr("data-label-long", $placeholder.html());
                        }
                    }
                    if (e.type == "focusout" && !is_filled)
                    {
                        if ($placeholder.attr("data-label-long"))
                        {
                            label = $placeholder.attr("data-label-long");
                        }
                    }
                    $placeholder.html(label);
                }
            }
            $(this).toggleClass("filled", is_filled);
        });

        $(".textfield").trigger("textfield-filled");
        $(document).ajaxStop(function () {
            $(".textfield").trigger("textfield-filled");
        });

        $("body").addClass("textfield-inited-globally")
    }

    $o.trigger("textfield-filled");

}




/*

Custom Checkboxes

 */

function checkbox_plain_init() {
    $(".checkbox-plain-js").find("[type='checkbox'], [type='radio']").not(".checkbox-plain-js-inited").on("change change-pseudo", function(e){
        var $o = $(this).closest(".checkbox-plain-js");
        if ($(this).is(":radio"))
        {
            var name = $(this).attr("name");
            var $radios = $(this).closest("form").find("[name='"+name+"']");
            if (!$radios.length) $radios = $("input[name='"+name+"']");
            $radios.closest(".checkbox-plain-js").removeClass("checked");
        }
        if ($(this).prop("checked")) $o.addClass("checked");
        else $o.removeClass("checked");
        if ($(this).prop("disabled")) $o.addClass("disabled");
        else $o.removeClass("disabled");
    }).on("focus", function(e){
        var $o = $(this).closest(".checkbox-plain-js");
        if (!$(this).prop("disabled")) $o.addClass("focused");
    }).on("blur", function(e){
        var $o = $(this).closest(".checkbox-plain-js");
        $o.removeClass("focused");
    }).each(function(){
        var $o = $(this).closest(".checkbox-plain-js");
        if ($(this).prop("checked")) $o.addClass("checked");
        if ($(this).prop("disabled")) $o.addClass("disabled");
    }).addClass("checkbox-plain-js-inited").closest(".checkbox-plain-js").addClass("checkbox-plain-js-inited").find("input:text").on("focus", function(){
        $(this).closest(".checkbox-plain-js").find(":checkbox,:radio").prop("checked", true).trigger("change");
    });
}






/*

Selectboxes

 */

function selectbox_init($o) {

    if (!$().select2) return;

    if (!$("body").hasClass("selectbox-inited")) {

        $(document).on("focus", '.select2-search__field', function(e) { // resolve focus problem in fancybox popup
            e.stopPropagation();
        });

        $.fn.select2.amd.define('select2/data/maximumSelectionLength1',[

        ], function (){
            function MaximumSelectionLength (decorated, $e, options) {
                this.maximumSelectionLength = options.get('maximumSelectionLength1');

                decorated.call(this, $e, options);
            }

            MaximumSelectionLength.prototype.query =
                function (decorated, params, callback) {
                    var self = this;

                    this.current(function (currentData) {
                        var count = currentData != null ? currentData.length : 0;
                        if (self.maximumSelectionLength1 > 0 &&
                            count >= self.maximumSelectionLength1) {
                            self.trigger('results:message', {
                                message: 'maximumSelected',
                                args: {
                                    maximum: self.maximumSelectionLength1
                                }
                            });
                            return;
                        }
                        decorated.call(self, params, callback);
                    });
                };

            return MaximumSelectionLength;
        });

        $("body").addClass("selectbox-inited");
    }

    $.fn.select2.amd.require([
        "select2/utils",
        "select2/dropdown",
        "select2/dropdown/attachContainer",
        "select2/dropdown/search",
        "select2/selection/search",
        "select2/dropdown/search",
        "select2/selection/base",
        "select2/selection/single",
        "select2/selection/multiple",
        "select2/core",
        "select2/selection/allowClear"
    ], function (Utils, DropdownAdapter, AttachContainer, DropdownSearch, SelectionSearch, DropdownSearch, BaseSelection, SingleSelection, MultipleSelection, Select2Core, AllowClear) {

        SingleSelection.prototype.render = function () {
            var $selection = SingleSelection.__super__.render.call(this);

            $selection.addClass('select2-selection--single');

            $selection.html(
                '<span class="select2-placeholder"><span class="select2-placeholder__inner">' + this.options.get('placeholder') +'</span></span>' +
                '<span class="select2-selection__rendered"></span>' +
                '<span class="select2-selection__arrow" role="presentation">' +
                '<svg class="icon icon--chevron-down-round">' +
                '<use xlink:href="'+$('body').data('svg-sprite-url')+'#icon-chevron-round-down"></use>' +
                '</svg>' +
                '</span>'
            );

            return $selection;
        };

        MultipleSelection.prototype.render = function () {
            var $selection = MultipleSelection.__super__.render.call(this);

            $selection.addClass('select2-selection--multiple');

            $selection.html(
                '<span class="select2-placeholder"><span class="select2-placeholder__inner">' + this.options.get('placeholder') +'</span></span>' +
                '<ul class="select2-selection__rendered"></ul>' +
                '<span class="select2-selection__arrow" role="presentation">' +
                '<svg class="icon icon--chevron-down-round">' +
                '<use xlink:href="'+$('body').data('svg-sprite-url')+'#icon-chevron-round-down"></use>' +
                '</svg>' +
                '</span>'
            );
            $selection.prop("tabindex", this.$element.data('old-tabindex'));

            return $selection;
        };

        DropdownSearch.prototype.render = function (decorated) {
            var $rendered = decorated.call(this);

            var $search = $(
                '<span class="select2-search select2-search--dropdown">' +
                '<div class="textfield-wrapper">' +
                '<span class="textfield-icon"><svg class="icon"><use xlink:href="#icon-search"></use></svg></span>' +
                '<input class="select2-search__field textfield" type="search" tabindex="-1"' +
                ' autocomplete="off" autocorrect="off" autocapitalize="off"' +
                ' spellcheck="false" role="textbox" />' +
                '</div>' +
                '</span>'
            );

            this.$searchContainer = $search;
            this.$search = $search.find('input');
            $rendered.prepend($search);

            return $rendered;
        };

        SelectionSearch.prototype._transferTabIndex = function (decorated) {
            // remove tabindex transfer
        };

        SelectionSearch.prototype.searchRemoveChoice = function (decorated, item) {

            this.trigger('unselect', {
                data: item
            });

            this.$search.val('');
            this.handleSearch();
        };

        AllowClear.prototype.update = function (decorated, data) {
            decorated.call(this, data);

            this.$selection.removeClass("select2-selection--with-clear");
            if (this.$selection.find('.select2-selection__placeholder').length > 0 || data.length === 0) {
                return;
            }

            var $remove = $(
                '<span class="select2-selection__clear">' +
                '&times;' +
                '</span>'
            );
            $remove.data('data', data);

            this.$selection.addClass("select2-selection--with-clear").find('.select2-selection__rendered').prepend($remove);
        };

        if (typeof $o === "undefined") $o = $('select.selectbox');
        $o.not('.selectbox-inited').each(function () {
            var options = {
                tags: false,
                theme: "default",
                templateResult: function (state) {
                    var output = state.text;
                    var html = $(state.element).data("html");
                    if (html) {
                        output = html;
                    }
                    return output;
                },
                templateSelection: function (state) {
                    var output = state.text;
                    var html = $(state.element).data("html");
                    if (html) {
                        output = html;
                    }
                    var html_result = $(state.element).data("html-result");
                    if (html_result) {
                        output = html_result;
                    }
                    return output;
                },
                escapeMarkup: function (text) {
                    return text;
                },
                dropdownAutoWidth: false,
                dropdownParent: $('.wrap')
            };
            if ($(this).data("selectbox-class"))
            {
                options["theme"] += " select2-container--" + $(this).data("selectbox-class");
            }
            if ($(this).data("selectbox-show-values"))
            {
                options["templateSelection"] = function(state) {
                    if (!state.id) {
                        return state.text;
                    }
                    var $state = state.element.value;
                    return $state;
                };
            }
            if (!$(this).data("selectbox-search"))
            {
                options["minimumResultsForSearch"] = Infinity;
            }
            if (typeof $(this).attr("data-placeholder") !== 'undefined')
            {
                options["placeholder"] = $(this).data("placeholder");
            }
            if ($(this).data("selectbox-autowidth"))
            {
                options["width"] = "auto";
            }
            if ($(this).data("selectbox-tags-source"))
            {
                options["dropdownParent"] = $(this).closest(".selectbox-tags__destination").find("select").parent();
                options["theme"] += " select2-container--tags-destination";
            }
            else
            {
                if ($(this).data("selectbox-tags"))
                {
                    var $towrap = $(this);
                    if ($(this).closest(".textfield-wrapper").length)
                    {
                        $towrap = $(this).closest(".textfield-wrapper");
                    }
                    var $cloned_select_html = $towrap.clone();
                    $towrap.wrap("<div class='selectbox-tags'>");
                    $cloned_select_html.insertAfter($towrap);
                    $towrap.wrap("<div class='selectbox-tags__source'>");
                    $cloned_select_html.wrap("<div class='selectbox-tags__destination'>");
                    options["dropdownParent"] = $towrap;
                    var $cloned_select_select = $cloned_select_html;
                    if ($cloned_select_html.find("select").length)
                    {
                        $cloned_select_select = $cloned_select_html.find("select");
                    }
                    var $cloned_select_placeholder = $cloned_select_html.find(".textfield-placeholder");
                    if ($cloned_select_placeholder.length)
                    {
                        $cloned_select_placeholder.html($cloned_select_placeholder.data("tags-label")?$cloned_select_placeholder.data("tags-label"):"");
                    }
                    $cloned_select_select.attr("data-placeholder", "").removeAttr("data-selectbox-aggregate-min");
                    $cloned_select_select.find("option[value!='']").remove();
                    $(this).removeAttr("name");
                    $(this).data("selectbox-tags-destination", $cloned_select_select);
                    $cloned_select_select.data("selectbox-tags-source", $(this));
                    if ($cloned_select_select.data("selectbox-tags-source"))
                    {
                        selectbox_init($cloned_select_select);
                    }
                    $(this).on("change", function(){
                        var $dest = $(this).data("selectbox-tags-destination");
                        sync_selects_append($(this)[0], $dest[0]);
                    });
                    $cloned_select_select.on("change", function(){
                        var $source = $(this).data("selectbox-tags-source");
                        sync_selects($(this)[0], $source[0]);
                    });
                    options["theme"] += " select2-container--tags-source";
                    options["containerCssClass"] = "select2-selection--notags";
                    options["dropdownCssClass"] = "select2-dropdown--notags";
                }
            }
            if (!$(this).data("selectbox-tags"))
            {
                options["containerCssClass"] = "select2-selection--notags";
                options["dropdownCssClass"] = "select2-dropdown--notags";
            }
            if ($(this).data("selectbox-tags-create"))
            {
                options["tags"] = $(this).data("selectbox-tags-create");
            }
            if ($(this).data("selectbox-allow-clear"))
            {
                options["allowClear"] = $(this).data("selectbox-allow-clear");
            }
            if ($(this).data("selectbox-limit"))
            {
                options["maximumSelectionLength"] = $(this).data("selectbox-limit");
            }
            if ($(this).prop("multiple"))
            {
                options["closeOnSelect"] = false;
            }
            if ($(this).data("selectbox-dropdown-parent"))
            {
                options["dropdownParent"] = $($(this).data("selectbox-dropdown-parent"));
            }
            if ($(this).data("selectbox-ajax"))
            {
                options["ajax"] = $(this).data("selectbox-ajax");
            }

            if ($(this).closest(".fancybox-slide").length)
            {
                options["theme"] += " select2-container--in-fancybox";
            }
            //$(this).find("option[value='']:empty").remove();
            $(this).off("change.selectbox").on("change.selectbox", function () {
                if (!$(this).prop("multiple")) {
                    $(this).select2('close');
                }
                $(this).data('select2').$container.toggleClass("select2-container--filled", !!$(this).val().length);
            }).select2(options).addClass("selectbox-inited");
            $(this).data('select2').$container.toggleClass("select2-container--filled", !!$(this).val().length);

            $(this).data('select2').on("results:all", function (params) {
                var $options = this.$dropdown.find(".select2-results__options");
                setTimeout(function(){
                    $options.toggleClass("select2-results__options--scrollbar", $options.hasScrollBar());
                }, 10);
            });

            $(this).off("select2:open").on("select2:open", function (e) {
                var _this = this;
                var $options = $(_this).data('select2').$dropdown.find(".select2-results__options");
                $(_this).data('select2').$dropdown.addClass("select2-container--dropdown");
                if ($(this).data("selectbox-dropdown-nowrap"))
                {
                    $(_this).data('select2').$dropdown.addClass("select2-container--dropdown-nowrap");
                }
                setTimeout(function(){
                    $options.toggleClass("select2-results__options--scrollbar", $options.hasScrollBar());
                    if (!detectIE()) {
                        $(_this).data('select2').$dropdown.find('.select2-search__field').attr("placeholder", "Поиск");
                    }
                    if (!$(_this).data('select2').$dropdown.closest(".select2-container--tags-source").length)
                    {
                        if ($(".touchevents").length) forceRedraw($(_this).data('select2').$dropdown[0]);
                        else $(_this).data('select2').$dropdown.css("transform", "translateZ(0)");
                    }
                }, 1);
            });

            $(this).off("select2:unselecting").on("select2:unselecting", function (e) {
                var select2 = $(this).data('select2');
                var opts = select2.options;
                opts.set('disabled', true);
                setTimeout(function() {
                    opts.set('disabled', false);
                }, 1);
            });

            $(this).off("select2:select").on("select2:select", function (e) {
                var _this = this;
                setTimeout(function() { // Hotfix for correct dropdown position if more than maximumSelectionLength
                    $(window).scrollTop($(window).scrollTop()+1);
                    $(window).scrollTop($(window).scrollTop()-1);
                }, 1);
            });

            $(this).off("select2:select select2:unselect select2:selection-aggregate").on("select2:select select2:unselect select2:selection-aggregate", function (e) {
                var select2 = $(this).data('select2');
                var opts = select2.options;
                var $rendered = select2.$selection.find(".select2-selection__rendered");
                var $ch_remove = select2.$selection.find(".select2-selection__choice__remove").parent("[title='']");
                $ch_remove.remove();
                /*if ($rendered.filter("span").length) { // не помню зачем... пока сохраню
                    var current_text = $.trim($rendered.filter("span").text());
                    if (current_text) {
                        $rendered.text(current_text);
                    }
                }*/

                /* http://stackoverflow.com/a/39787191 */

                var values = [];
                $(this).find("option:selected").each(function(i, selected){
                    values[i] = $(selected).text();
                });

                var agg_min = $(this).data("selectbox-aggregate-min");
                if (agg_min) {
                    var output = "";
                    if (values.length >= agg_min)
                    {
                        output = "<li class='select2-selection__choice'>Выбрано "+values.length+"</li>";
                    }
                    if (output)
                    {
                        $rendered.children().not(".select2-selection__clear").remove();
                        $rendered.prepend(output);
                    }
                }
                if (!values.length) {
                    $rendered.find(".select2-selection__clear").remove();
                }
            }).trigger("select2:selection-aggregate");

        });
    }, null, true);

}

function sync_selects(el1, el2) {
    if ($(el1).data("change-triggering"))
    {
        return false;
    }
    if (!el1)
    {
        return false;
    }
    else
    {
        var options1 = el1.getElementsByTagName('option');
        var options2 = el2.getElementsByTagName('option');
        for (var i = 0, len = options1.length; i < len; i++)
        {
            var val = options1[i].value;
            $(options2).filter("[value='"+val+"']").prop("selected", options1[i].selected);
        }
        $(el2).data("change-triggering", true).trigger("change").trigger("select2:selection-aggregate").data("change-triggering", false);
    }
}

function sync_selects_append(el1, el2) {
    var change_reverse = $(el1).data("change-reverse");
    if ($(el1).data("change-triggering"))
    {
        return false;
    }
    if (!el1)
    {
        return false;
    }
    else {
        var options1 = el1.getElementsByTagName('option');
        var options2 = el2.getElementsByTagName('option');
        if (change_reverse) {
            options3 = options1;
            options1 = options2;
            options2 = options3;
            el3 = el1;
            el1 = el2;
            el2 = el3;
        }
        for (var i = 0, len = options1.length; i < len; i++) {
            var val = options1[i].value;
            if (!change_reverse) {
                var exist = $(options2).filter("[value='" + val + "']").length;
                if (!exist) {
                    $(el2).append($(options1[i]).clone());
                }
            }
            $(options2).filter("[value='" + val + "']").prop("selected", options1[i].selected);
        }
        $(el2).data("change-triggering", true).trigger("change").trigger("select2:selection-aggregate").data("change-triggering", false);
    }
}

(function ($) {
    $.fn.refreshDataSelect2 = function (data) {
        this.select2('data', data);

        // Update options
        var $select = $(this[0]);
        var options = data.map(function(item) {
            return '<option value="' + item.id + '">' + item.text + '</option>';
        });
        $select.html('<option value=""></option>');
        $select.append(options.join('')).trigger("change");
    };
})(jQuery);