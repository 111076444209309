


/*

 Loaders (for buttons)

 */

function loader_init() {
    $btn = $(".loader-on-page-load");
    loader_add($btn);
    $btn.prop("disabled", true);
}

function loader_add($o) {
    var loader = '<span class="loader"><svg version="1.1" id="loader-1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"\
    width="40px" height="40px" viewBox="0 0 50 50" style="enable-background:new 0 0 50 50;" xml:space="preserve">\
        <path fill="#fff" d="M25.251,6.461c-10.318,0-18.683,8.365-18.683,18.683h4.068c0-8.071,6.543-14.615,14.615-14.615V6.461z">\
        <animateTransform attributeType="xml"\
            attributeName="transform"\
            type="rotate"\
            from="0 25 25"\
            to="360 25 25"\
            dur="0.6s"\
            repeatCount="indefinite"/>\
        </path>\
        </svg></div>';
    if ($o.closest('.btn').length) {
        $o.wrapInner("<div class='btn__loader-inner'>");
    }
    $o.append(loader).addClass("loading");
}

function loader_remove($o) {
    $o.find(".btn__loader-inner").children().unwrap();
    $o.removeClass("loading").find("> .loader").remove();
}



/*

Tabs

 */

function tabs_init() {

    $(document).on("click", ".js-tabs a", function (e, eparams) {
        var $tabs = $(this).closest(".js-tabs");

        $(this).parent().siblings().removeClass("active");
        if ($(this).closest(".js-tabs-toggle").length) {
            $(this).parent().toggleClass("active");
        }
        else {
            $(this).parent().addClass("active");
        }

        tabs_update_pointer($tabs);

        $ostt = $(this).closest(".js-tabs-onclick-scroll-to-tabs");
        if ($ostt.length) {
            $("html, body").stop(true, true).animate({scrollTop: $ostt.offset().top - ($(".header__top--fixed").outerHeight()?$(".header__top--fixed").outerHeight():0) + ($ostt.data("tabs-scroll-offset")?$ostt.data("tabs-scroll-offset"):0) - ($ostt.data("tabs-scroll-offset-element")?$($ostt.data("tabs-scroll-offset-element")).outerHeight():0)}, 500);
        }

        if ($(this).hasClass("js-tabs-ignore") || $tabs.hasClass("js-tabs-ignore")) {
            return true;
        }

        var $o = $($(this).attr("href"));

        if ($o.length) {
            e.preventDefault();
            if ($(this).closest(".js-tabs-toggle").length) {
                $o.toggleClass("active");
            }
            else {
                $o.addClass("active");
            }

            $o.siblings(".tab-pane").removeClass("active");
            if ($o.hasClass("active")) $o.closest(".tab-content-change-height").css("height", 0).css("height", $o.outerHeight());
            else $o.closest(".tab-content-change-height").css("height", 0);
            if ($(this).closest(".js-tabs").hasClass("js-tabs-hash-change")) {
                if (typeof eparams === "undefined" || typeof eparams.nohash === "undefined" || !eparams.nohash) {
                    location_hash_update($(this).attr("href"));
                }
            }
            $o.filter(".active").find(":input:first").trigger("check-form");
            $o.siblings(".tab-pane").find(":input.error").each(function () {
                var $form = $(this).closest("form");
                var validator = $form.data("validator");
                if (validator) $(this).valid();
            });
            $(window).trigger("scroll.fixed-hscroll");
        }
    });

    tabs_scroll_init();
    $(document).ajaxStop(function () {
        tabs_scroll_init();
    });

    $(window).on("load", function(){
        if (location.hash) {
            if ($(location.hash).filter(".tab-pane").length) {
                $(".js-tabs a[href='" + location.hash + "']").trigger("click");
            }
            else if ($(".tab-pane").find(location.hash).length) {
                $(".js-tabs a[href='#" + $(".tab-pane").find(location.hash).closest(".tab-pane").attr("id") + "']").trigger("click", {nohash: true});
            }
        }
    });

    tabs_update_pointer($(".tabs"));
    $(".js-tabs-fixed-center a").on("click-tabs-fixed-center", function (e) {
        tabs_update_pointer($(this).closest(".tabs"));
    });
    $(window).on("resize orientationchange", function () {
        tabs_update_pointer($(".tabs"));
        $(".js-tabs-fixed-center-scroll").trigger("scroll.emulate");
    });
}

function tabs_scroll_init() {
    $(".js-tabs-fixed-center-scroll").not(".js-tabs-fixed-center-scroll-inited").on("scroll scroll.emulate", function (e) {
        var $tabs = $(this).closest(".js-tabs-fixed-center");
        if ($(this).scrollLeft() <= 0) {
            $tabs.addClass("scroll-in-start");
        }
        else {
            $tabs.removeClass("scroll-in-start");
        }
        if ($(this).scrollLeft() + $(this).width() >= $(this)[0].scrollWidth - 1) {
            $tabs.addClass("scroll-in-end");
        }
        else {
            $tabs.removeClass("scroll-in-end");
        }
    }).addClass("js-tabs-fixed-center-scroll-inited").trigger("scroll.emulate");

    $(".js-tabs-fixed-center a").not(".js-tabs-fixed-center-scroll-inited").on("click click-tabs-fixed-center", function (e) {
        var $tabs = $(this).closest(".js-tabs-fixed-center");
        var $ul = $(this).closest(".js-tabs-fixed-center-scroll");
        $ul.stop(true, true).animate({scrollLeft: $(this).parent()[0].offsetLeft - $tabs.width() / 2 + $(this).outerWidth() / 2}, 300);
    }).addClass("js-tabs-fixed-center-scroll-inited");

    $(".js-tabs-scroll-prev").not(".js-tabs-fixed-center-scroll-inited").on("click", function (e) {
        e.preventDefault();
        var $tabs = $(this).closest(".js-tabs-fixed-center");
        var $ul = $tabs.find(".js-tabs-fixed-center-scroll");
        $ul.stop(true, true).animate({scrollLeft: $ul.scrollLeft() - $ul.width()*0.75}, 300);
    }).addClass("js-tabs-fixed-center-scroll-inited");

    $(".js-tabs-scroll-next").not(".js-tabs-fixed-center-scroll-inited").on("click", function (e) {
        e.preventDefault();
        var $tabs = $(this).closest(".js-tabs-fixed-center");
        var $ul = $tabs.find(".js-tabs-fixed-center-scroll");
        $ul.stop(true, true).animate({scrollLeft: $ul.scrollLeft() + $ul.width()*0.75}, 300);
    });

    $(".js-tabs-fixed-center li.active a").trigger("click-tabs-fixed-center");
}

function tabs_update_pointer($tabs) {
    $tabs.each(function () {
        var $pointer = $(this).find(".js-tabs-pointer");
        var $scroll = $pointer.closest(".tabs__scroll");
        var scroll_offset = 0;
        if ($pointer.length) {
            if ($scroll.length) {
                scroll_offset = $scroll[0].scrollLeft;
            }
            $pointer.css({
                left: $(this).find("li.active").offset().left - $(this).offset().left + scroll_offset,
                width: $(this).find("li.active").width(),//outerWidth
            }).addClass("active");
        }
    });
}


/*

Slider

 */


function slider_init($s)
{
    slider_swiper_init($s);
}

function slider_swiper_init($s)
{
    if (typeof Swiper !== "undefined")
    {
        if (typeof $s === "undefined") $s = $(".swiper-slider").filter(":not(.slider-events-added)");
        $s.each(function(){
            var $this = $(this);
            var swiper = new Swiper($this.find('.swiper-container')[0], {
                init: false,
                direction: (typeof $this.data("slider-direction") !== "undefined")?$this.data("slider-direction"):'horizontal',
                loop: (typeof $this.data("slider-loop") !== "undefined")?$this.data("slider-loop"):false,
                loopAdditionalSlides: $this.find('.swiper-slide').length*5,
                loopedSlides: $this.find('.swiper-slide').length*5,
                autoplay: (typeof $this.data("slider-autoplay") !== "undefined")?{ delay: $this.data("slider-autoplay") }:false,
                initialSlide: (typeof $this.data("slider-initial-slide") !== "undefined")?$this.data("slider-initial-slide"):0,
                pagination: {
                    el: (typeof $this.data("slider-pagination") !== "undefined")?$this.data("slider-pagination"):$this.find('.swiper-pagination')[0],
                    type: (typeof $this.data("slider-pagination-type") !== "undefined")?$this.data("slider-pagination-type"):'bullets',
                    bulletElement: (typeof $this.data("slider-pagination-element") !== "undefined") ? $this.data("slider-pagination-element") : 'span',
                    clickable: (typeof $this.data("slider-pagination-clickable") !== "undefined")?$this.data("slider-pagination-clickable"):true,
                    renderBullet: function(index, className) {
                        var $slider = $(this.$el).closest(".swiper-slider");
                        var name = $(this.slides).eq(index).attr("data-pagination-name");
                        var summary = $(this.slides).eq(index).attr("data-pagination-summary");
                        var tag = 'span';
                        if ($slider.length)
                        {
                            if ($slider.data("slider-pagination-element"))
                            {
                                tag = $slider.data("slider-pagination-element");
                            }
                        }
                        if (!name) {
                            name = "";
                        }
                        else {
                            name = "<div class='swiper-pagination-name'>" + name + "</div>";
                        }
                        if (!summary) {
                            summary = "";
                        }
                        else {
                            summary = "<div class='swiper-pagination-summary'>" + summary + "</div>";
                        }
                        return '<'+tag+' class="' + className + '">' + name + summary + '</'+tag+'>';
                    },
                },
                scrollbar: ($this.find('.swiper-scrollbar').length)?{ el: $this.find('.swiper-scrollbar')[0] }:{},
                scrollbarHide: (typeof $this.data("slider-scrollbar-hide") !== "undefined")?$this.data("slider-scrollbar-hide"):false,
                scrollbarDraggable: (typeof $this.data("slider-scrollbar-draggable") !== "undefined")?$this.data("slider-scrollbar-draggable"):true,
                scrollbarSnapOnRelease: (typeof $this.data("slider-scrollbar-snap-on-release") !== "undefined")?$this.data("slider-scrollbar-snap-on-release"):true,
                freeMode: (typeof $this.data("slider-free-mode") !== "undefined")?$this.data("slider-free-mode"):false,
                autoHeight: (typeof $this.data("slider-auto-height") !== "undefined")?$this.data("slider-auto-height"):false,
                centeredSlides: (typeof $this.data("slider-centered-slides") !== "undefined")?$this.data("slider-centered-slides"):false,
                slidesPerView: (typeof $this.data("slider-slides-per-view") !== "undefined")?$this.data("slider-slides-per-view"):1,
                slidesPerGroup: (typeof $this.data("slider-slides-per-group") !== "undefined")?$this.data("slider-slides-per-group"):1,
                effect: (typeof $this.data("slider-effect") !== "undefined")?$this.data("slider-effect"):"slide",
                fadeEffect: {
                    crossFade: true
                },
                preloadImages: (typeof $this.data("slider-lazy-loading") !== "undefined")?!$this.data("slider-lazy-loading"):true,
                simulateTouch: (typeof $this.data("slider-simulate-touch") !== "undefined")?$this.data("slider-simulate-touch"):true,
                allowTouchMove: (typeof $this.data("slider-allow-touch-move") !== "undefined")?$this.data("slider-allow-touch-move"):true,
                spaceBetween: (typeof $this.data("slider-space-between") !== "undefined")?$this.data("slider-space-between"):0,
                navigation: {
                    prevEl: (typeof $this.data("slider-prev-button") !== "undefined")?$this.data("slider-prev-button"):$this.find('.swiper-button-prev'),
                    nextEl: (typeof $this.data("slider-next-button") !== "undefined")?$this.data("slider-next-button"):$this.find('.swiper-button-next'),
                },
                speed: (typeof $this.data("slider-speed") !== "undefined")?$this.data("slider-speed"):300,
                buttonDisabledClass: 'disabled',
                slidesOffsetBefore: (typeof $this.data("slider-slides-offset-before") !== "undefined")?$this.data("slider-slides-offset-before"):0,
                slidesOffsetAfter: (typeof $this.data("slider-slides-offset-after") !== "undefined")?$this.data("slider-slides-offset-after"):0,
                roundLengths: (typeof $this.data("slider-round-lengths") !== "undefined")?$this.data("slider-round-lengths"):true,
                lazy: (typeof $this.data("slider-lazy-loading") !== "undefined")?$this.data("slider-lazy-loading"):false,
                nested: (typeof $this.data("slider-nested") !== "undefined")?$this.data("slider-nested"):false,
                resistanceRatio: (typeof $this.data("slider-resistance-ratio") !== "undefined")?$this.data("slider-resistance-ratio"):0.85,
                breakpoints: (typeof $this.data("slider-breakpoints") !== "undefined")?$this.data("slider-breakpoints"):null,
                slideToClickedSlide: (typeof $this.data("slider-slide-to-clicked-slide") !== "undefined")?$this.data("slider-slide-to-clicked-slide"):false,
                mousewheelControl: (typeof $this.data("slider-mousewheel-control") !== "undefined")?$this.data("slider-mousewheel-control"):false,
                mousewheelReleaseOnEdges: (typeof $this.data("slider-mousewheel-release-on-edges") !== "undefined")?$this.data("slider-mousewheel-release-on-edges"):false,
                zoom: (typeof $this.data("slider-zoom") !== "undefined")?$this.data("slider-zoom"):false
            });

            $this.find('.swiper-container')[0].swiper.on('transitionStart', function (e) {
                var $activeSlide = $(this.slides).eq(this.activeIndex);
                $activeSlide.find(".swiper-lazy-preloader").css({
                    top: this.height/2
                });
                $(this.slides).not($activeSlide).find('video').each(function(){
                    this.pause();
                });
            });


            $this.find('.swiper-container')[0].swiper.on('init progress resize', function (e) {
                if (typeof this.allowTouchMoveRemembered === 'undefined') {
                    this.allowTouchMoveRemembered = this.allowTouchMove;
                }
                if (this.isBeginning && this.isEnd) {
                    $(this.navigation.$prevEl).addClass("swiper-button-unneeded");
                    $(this.navigation.$nextEl).addClass("swiper-button-unneeded");
                    this.allowTouchMove = false;
                } else {
                    $(this.navigation.$prevEl).removeClass("swiper-button-unneeded");
                    $(this.navigation.$nextEl).removeClass("swiper-button-unneeded");
                    if (typeof this.allowTouchMoveRemembered !== 'undefined') {
                        this.allowTouchMove = this.allowTouchMoveRemembered;
                    }
                }
            });

            $this.find('.swiper-container')[0].swiper.on('init transitionStart resize', function (e) {
                var $slider = $(this.$el).closest(".swiper-slider");
                $slider.attr("data-active-slide", this.realIndex);
            });

            $this.find('.swiper-container')[0].swiper.on('progress', function (progress) {
                progressCallback(this);
            });
            progressCallback($this.find('.swiper-container')[0].swiper);


            $this.find('.swiper-container')[0].swiper.on('onLazyImageReady', function (swiper) {
                swiper.setWrapperTransition(swiper.params.speed);
                swiper.updateAutoHeight();
            });

            swiper.init();

        }).addClass("slider-events-added");


        $s.each(function(){
            var $this = $(this);

            if (typeof $this.data("slider-control-thumbs") !== "undefined" && typeof $this.find('.swiper-container')[0].swiper !== "undefined")
            {
                var $thumbs_swiper = $($this.data("slider-control-thumbs"));
                var $thumbs_swiper_cs = $thumbs_swiper.find('.swiper-container');
                if ($thumbs_swiper_cs.length) {

                    $thumbs_swiper_cs.each(function() {
                        var $thumbs_swiper_c = $(this);

                        if (typeof $thumbs_swiper_c[0].swiper !== "undefined") {
                            var thumbs_swiper = $thumbs_swiper_c[0].swiper;
                            thumbs_swiper.thumbs_goal_swiper = $this.find('.swiper-container')[0].swiper;
                            thumbs_swiper.on('tap', function (e) {
                                var clicked = this.clickedIndex;
                                //swiper.activeIndex = clicked; //don't need this
                                //swiper.updateClasses(); //don't need this
                                $(this.slides).removeClass('is-selected');
                                $(this.clickedSlide).addClass('is-selected');
                                this.slideTo(clicked, this.params.speed, false);
                                this.thumbs_goal_swiper.slideTo(clicked, this.thumbs_goal_swiper.params.speed, true);
                            });
                        }

                        $this.find('.swiper-container')[0].swiper.on('transitionStart transitionEnd', function (e) {
                            var $o_cs = $(this.$el.closest(".swiper-slider").data("slider-control-thumbs"));
                            var activeIndex = this.activeIndex;
                            $o_cs.find('.swiper-container').each(function() {
                                var $o = $(this);
                                if (typeof $o[0].swiper !== "undefined") {
                                    var thumbs_swiper = $o[0].swiper;
                                }
                                if (typeof thumbs_swiper !== "undefined") {
                                    $(thumbs_swiper.slides).removeClass('is-selected');
                                    $(thumbs_swiper.slides).eq(activeIndex).addClass('is-selected');
                                    thumbs_swiper.slideTo(activeIndex, thumbs_swiper.params.speed, false);
                                }
                            });
                        });
                        $this.find('.swiper-container')[0].swiper.transitionEnd();
                    });
                }
            }

        });

        function progressCallback(swiper) {
            var $slider = $(swiper.$el).closest(".swiper-slider");
            $slider.removeClass("swiper-slider--start swiper-slider--end");
            if (swiper.isBeginning) {
                $slider.addClass("swiper-slider--start");
            }
            if (swiper.isEnd) {
                $slider.addClass("swiper-slider--end");
            }
        }

        $(window).on("resize orientationchange", function(){
            delay_slider_resize(function(){
                $(".swiper-slider.slider-events-added").each(function(){
                    if ($(this).data("reinitialize-on-resize") && typeof $(this).find(".swiper-container")[0].swiper !== "undefined")
                    {
                        var swiper = $(this).find(".swiper-container")[0].swiper;
                        swiper.destroy(true, true);
                        $(this).find('.swiper-slide-duplicate').remove();
                        slider_swiper_init($(this));
                    }
                });
            }, 300);
        });

        $(window).trigger("resize-swiper-wrapper-center-if-less");
    }
}

var delay_slider_resize = (function(){
    var timer = 0;
    return function(callback, ms){
        clearTimeout (timer);
        timer = setTimeout(callback, ms);
    };
})();








/*

Gallery and Modal Popups

 */

function fancybox_init() {

    if (!$("body").hasClass("fancybox-inited")) {

        $.fancybox.defaults.i18n.ru = {
            CLOSE: "Закрыть",
            NEXT: "Далее",
            PREV: "Назад",
            ERROR: "Загрузка контента не удалась. <br/> Пожалуйста, попробуйте ещё раз.",
            PLAY_START: "Начать слайдшоу",
            PLAY_STOP: "Приостановить слайдшоу",
            FULL_SCREEN: "Во весь экран",
            THUMBS: "Предпросмотр",
            DOWNLOAD: "Скачать",
            SHARE: "Поделиться",
            ZOOM: "Масштаб"
        };
        $.fancybox.defaults.lang = $('html').attr('lang');

        if ($().fancybox) {
            $.fancybox.options_modal = {
                slideShow: false,
                hash: false,
                clickContent: false,
                closeExisting: true,
                clickSlide: "close",
                clickOutside: "close",
                keyboard: true,
                mobile: {
                    clickSlide: false
                },
                ajax: {
                    settings: {
                        cache: false
                    }
                },
                baseClass: "fancybox-container--popup",
                trapFocus: false,
                autoFocus: false,
                touch: false,
                popup_default: true,
                btnTpl : {
                    smallBtn: '<div data-fancybox-close class="fancybox-close">\
                            <svg class="icon icon--cross">\
                                <use xlink:href="'+$('body').data('svg-sprite-url')+'#icon-cross"></use>\
                            </svg></div>'
                },
                afterLoad: function (instance, current) {
                    current.$content.wrap("<div class='fancybox-content-wrapper'>");
                    current.$content.find('svg.icon use').each(function(){
                        $(this).attr('xlink:href', $('body').data('svg-sprite-url') + $(this).attr('xlink:href'));
                    });
                    var $small_btn_place = current.$content.find("[data-fancybox-small-btn-place]");
                    if ($small_btn_place.length) {
                        current.$content.find(".fancybox-close").appendTo($small_btn_place);
                    }
                },
            };
            if ($().tooltipster) {
                $.fancybox.defaults.beforeLoad = function (instance, current) {
                    var tooltips = $.tooltipster.instances();
                    $.each(tooltips, function (i, tooltip) {
                        tooltip.close();
                    });
                };
            }
            $.fancybox.defaults.hash = false;
        }

        $('body').on('mousewheel', function (e) {
            if ($(".fancybox-is-zoomable").length) {
                e.preventDefault();
                var instance = $.fancybox.getInstance();
                if ($(".fancybox-is-zoomable").length && instance.canPan() && e.deltaY > 0) {
                    instance.scaleToFit();
                } else if ($(".fancybox-can-zoomIn").length && instance.isScaledDown() && e.deltaY < 0) {
                    instance.scaleToActual(e.clientX, e.clientY);
                }
            }
        });

        $(document).on("click", "[data-fancybox-close-current]", function (e) {
            e.preventDefault();
            $.fancybox.close();
        });

        $("body").addClass("fancybox-inited");
    }

    if (jQuery().fancybox) {

        var options = {
            slideShow: false,
            hash: true,
            loop: true,
            idleTime: 10,
            margin: [44, 0],
            gutter: 50,
            keyboard: true,
            animationEffect: "zoom",
            arrows: true,
            infobar: true,
            toolbar: true,
            buttons: [
                'slideShow',
                'fullScreen',
                'thumbs',
                'close'
            ],
            thumbs: {
                autoStart: false,
                hideOnClose: true
            },
        };
        // options = jQuery.extend(true, options, options_lang);

        $fancybox_links_all = $("[data-fancybox]").not(".fancybox-inited");

        $fancybox_links = $fancybox_links_all.not("[data-type='ajax']");
        fancybox_links_by_group = [];
        groups = [];
        $fancybox_links.each(function () {
            var group = $(this).attr("data-fancybox");
            if (!group) group = "";
            if ($.inArray(group, groups) < 0) groups.push(group);
        });
        for (group in groups) {
            options_current = $.extend(true, {}, options);
            var $items = $fancybox_links.filter("[data-fancybox='" + groups[group] + "']");
            var $first = $items.eq(0);
            if (typeof $first.attr("data-fancybox-loop") !== "undefined") {
                options_current["loop"] = $first.data("fancybox-loop");
            }
            $items.fancybox(options_current).addClass("fancybox-inited");
        }

        $fancybox_links_ajax = $fancybox_links_all.filter("[data-type='ajax']");
        $fancybox_links_ajax.each(function () {
            if ($(this).attr("data-type") == "ajax" || $(this).attr("data-src")) {
                options = $.fancybox.options_modal;
            }
            $(this).fancybox(options);
        }).addClass("fancybox-inited");
    }
}




/*

Google Map UI

 */




function gmap_load()
{
    if ($(".map").length && !$("#api-maps-google").length)
    {
        var script = document.createElement('script');
        script.id = 'api-maps-google';
        script.type = 'text/javascript';
        script.src = 'https://maps.googleapis.com/maps/api/js?callback=gmap_init&key=AIzaSyArXFUoj17I4IKRuUSeRiWUakoxs6mHKiE&language='+$('html').attr('lang');
        document.body.appendChild(script);
    }
    else if ($("#api-maps-google").length) {
        gmap_init();
    }
}

function gmap_init()
{
    if (typeof google === "undefined") return;
    CustomMarkerInit();
    $(".map:not(.map--inited)").each(function(){
        var center = $(this).data("center").split(",");
        var zoom = $(this).data("zoom");
        var map_center_point = new google.maps.LatLng(center[0], center[1]);

        var mapOptions = {
            zoom: zoom,
            center: map_center_point,
            panControl: false,
            zoomControl: false,
            mapTypeControl: false,
            scaleControl: false,
            //disableDoubleClickZoom: true,
            streetViewControl: false,
            scrollwheel: false,
            draggable: true,
            disableDefaultUI: true,
            overviewMapControl: false,
            gestureHandling: Modernizr.touchevents?'cooperative':false,
            styles: [
                {
                    "featureType": "administrative",
                    "elementType": "labels.text.fill",
                    "stylers": [
                        {
                            "color": "#444444"
                        }
                    ]
                },
                {
                    "featureType": "landscape",
                    "elementType": "all",
                    "stylers": [
                        {
                            "color": "#f2f2f2"
                        }
                    ]
                },
                {
                    "featureType": "poi",
                    "elementType": "all",
                    "stylers": [
                        {
                            "visibility": "off"
                        }
                    ]
                },
                {
                    "featureType": "road",
                    "elementType": "all",
                    "stylers": [
                        {
                            "saturation": -100
                        },
                        {
                            "lightness": 45
                        }
                    ]
                },
                {
                    "featureType": "road.highway",
                    "elementType": "all",
                    "stylers": [
                        {
                            "visibility": "simplified"
                        }
                    ]
                },
                {
                    "featureType": "road.arterial",
                    "elementType": "labels.icon",
                    "stylers": [
                        {
                            "visibility": "off"
                        }
                    ]
                },
                {
                    "featureType": "transit",
                    "elementType": "all",
                    "stylers": [
                        {
                            "visibility": "off"
                        }
                    ]
                },
                {
                    "featureType": "water",
                    "elementType": "all",
                    "stylers": [
                        {
                            "color": "#46bcec"
                        },
                        {
                            "visibility": "on"
                        }
                    ]
                }
            ]
        };

        var map = new google.maps.Map($(this)[0], mapOptions);
        $(this).data("map", map);
        map._div = $(this);
        $(this).data("map", map);
        map._div = $(this);

        google.maps.event.addListenerOnce(map, 'idle', function(){
            var $map = $(this._div);
            var this_map = this;
            setTimeout(function(){
                google.maps.event.trigger(this_map, "resize");
            }, 0);
        });

        $(window).on('resize orientationchange', function() {
            map_set_default_center();
        });

        if ($(this).data("point"))
        {
            var point = $(this).data("point").split(",");
            var marker_class = $(this).data("marker-class");
            map_set_marker(map, point, marker_class);
        }

        $(this).addClass("map--inited");
    });
}

function map_set_marker(map, point, marker_class)
{
    if (typeof marker_class === "undefined") {
        marker_class = 'default';
    }
    if (point.length > 1)
    {
        var point_name = point[0]+"_"+point[1];
        if (!$(map._div).data("markers")) {
            $(map._div).data("markers", {});
        }
        if (!$(map._div).data("markers")[point_name]) {
            var marker = new CustomMarker(
                new google.maps.LatLng(point[0], point[1]),
                map,
                {
                    html: "<div class='marker-pin marker-pin--"+marker_class+"'>\
                        <svg class='icon'>\
                            <use xlink:href='" + $('body').data('svg-sprite-url') + "#icon-marker-white'></use>\
                        </svg></div>"
                }
            );
            var marker_obj = {};
            marker_obj[point_name] = marker;
            $(map._div).data("markers", $.extend({}, $(map._div).data("markers"), marker_obj));
        }
    }
}

function map_set_default_center()
{
    $(".map.map--inited").each(function(){
        var $map = $(this);
        if ($map.length && $map.data("map"))
        {
            var center = $map.data("center").split(",");
            $map.data("map").setCenter(new google.maps.LatLng(center[0], center[1]));

            if ($map.data("pan-by"))
            {
                var panby = $map.data("pan-by").split(",");
                if (panby.length > 1)
                {
                    if (panby[0].indexOf("%") !== -1) panby[0] = $map.width()*parseFloat(panby[0])/100;
                    $map.data("map").panBy(1*panby[0], 1*panby[1]);
                }
            }
        }
    });
}

function CustomMarker(latlng, map, args) {
    this.latlng = latlng;
    this.args = args;
    this.setMap(map);
}

function CustomMarkerInit()
{
    if (typeof CustomMarker.prototype.draw !== "undefined") return;
    CustomMarker.prototype = new google.maps.OverlayView();

    CustomMarker.prototype.draw = function() {

        var self = this;

        var div = this.div;

        if (!div) {

            div = this.div = document.createElement('div');
            div.style.position = 'absolute';

            if (typeof self.args !== 'undefined' && typeof self.args.html !== 'undefined') {
                var $div = $(div);
                $div.html(self.args.html);
                div = $div[0];
            }

            if (typeof self.args !== 'undefined' && typeof self.args.marker_id !== 'undefined') {
                div.dataset.marker_id = self.args.marker_id;
            }

            google.maps.event.addDomListener(div, "click", function(event) {
                google.maps.event.trigger(self, "click");
            });

            var panes = this.getPanes();
            panes.overlayImage.appendChild(div);
        }

        var point = this.getProjection().fromLatLngToDivPixel(this.latlng);

        if (point) {
            div.style.left = (point.x) + 'px';
            div.style.top = (point.y) + 'px';
        }
    };

    CustomMarker.prototype.remove = function() {
        if (this.div) {
            this.div.parentNode.removeChild(this.div);
            this.div = null;
        }
    };

    CustomMarker.prototype.getPosition = function() {
        return this.latlng;
    };
}