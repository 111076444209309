$(function () {
    document_ready();
    window_onload();
});

function document_ready() {
    ts = (new Date()).getTime();
    $("body").addClass("ready");
    var ie = detectIE();
    if (ie) {
        $("body").addClass("msie-"+ie);
        if (ie < 12) {
            $("body").addClass("msie");
        }
        else {
            $("body").addClass("msedge");
        }
    }

    responsive_init();
    init_event_handlers();

    bind_widgets();
    $(document).ajaxStop(function () {
        bind_widgets();
    });

    te = (new Date()).getTime();
    console.log("On-Ready Load Time: ", te - ts);
}

function window_onload() {
    ts = (new Date()).getTime();
    $("body").addClass("win-loaded").removeClass("win-not-loaded");

    $(document).imagesLoaded(function () {
        te = (new Date()).getTime();
        console.log("Images Load Time: ", te - ts);
        setTimeout(function () {
            $("body").addClass("loaded").removeClass("not-loaded");

        }, 10);
    });

    responsive_update();

    te = (new Date()).getTime();
    console.log("Window Load Time: ", te - ts);
}

$(window).scroll(function () {
    if (typeof $("body").data("scrollval") === "undefined") $("body").data("scrollval", 0);

    scroll_animations();

    $("body").data("scrollval", $(window).scrollTop());
});

$(window).on("resize", function (e) {
    responsive_update();
    scroll_animations();
});

function init_event_handlers() {

    scroll_animations_init();
    fix_100vh_init();
    fix_touch_hovers();
    click_touch_init();
    textfield_init();
    tabs_init();
    goto_init();
    expand_it_init();
    toggle_element_init();

    // HEADER and MENU

    $(document).on("click", ".js-menu-switcher", function (e) {
        e.preventDefault();
        $("html").toggleClass("menu-overlay-active");
    });

    $(document).on("click", ".js-menu-hide", function (e) {
        e.preventDefault();
        $("html").removeClass("menu-overlay-active");
    });

    $(document).on("click", "*", function (e) {
        if (!$(e.target).closest(".menu-overlay, .js-menu-switcher").length) {
            $("html").removeClass("menu-overlay-active");
        }
    });

    $(document).on("touchmove", ".menu-overlay", function (e) {
        e.stopPropagation();
    });

    $(document).on("click", ".js-services-menu a", function (e) {
        $(this).parent().prev().addClass('prev-active').siblings().removeClass('prev-active');
        $('.js-services-smenu-value').html($(this).text());
        $('.js-services-smenu-value').closest('.expand-it').trigger('click');
    });

    $(document).on("click", ".js-hcity-switcher", function (e) {
        $('.hcity').toggleClass('active');
    });

    $(document).on("click", ".js-hcity-hide", function (e) {
        e.preventDefault();
        $('.hcity').removeClass('active');
    });

    $(document).on("click", ".js-hcity-submenu li a", function (e) {
        e.preventDefault();
        $('.js-hcity-content').html($(this).html());
        $('.hcity').removeClass('active');
    });

    $(document).on("click", ".menu-overlay .hmenu .expand-it-pseudo", function (e) {
        var $o = $(this);
        var $exp = $o.closest(".expand-it-wrapper").find(".expand-it-container").eq(0);
        //$o.toggleClass("focused");
        e.preventDefault();
        expand_it_trigger($o, $exp, false);
    });

    form_process_init();

}

function form_process_init() {

    $(document).on("submit.valid click perform-action", ".js-action", function(e){
        var $this = $(this);
        if ($this.is("form") && e.type != "submit") {
            return true;
        }
        e.preventDefault();
        var url = $this.attr("href");
        var method = "get";
        var data = null;
        if ($this.is("form")) {
            data = $this.serialize();

            if (window.FormData !== undefined) {
                data = new FormData($this[0]);
            }

            url = $this.attr("action");
            method = $this.attr("method");
        }
        if ($this.attr("data-action-url")) {
            url = $this.attr("data-action-url");
        }
        if ($this.attr("data-action-method")) {
            method = $this.attr("data-action-method");
        }

        $.ajax({
            url: url,
            type: method,
            data: data,
            dataType: 'json',
            cache: false,
            contentType: false,
            processData: false,
            beforeSend: function () {
                $this.find(":input").prop("disabled", true).closest(".textfield-wrapper").addClass("disabled");
                var $btn = $this.find(".loader-on-submit");
                loader_add($btn);
            },
            complete: function (response) {
                $this.find(":input").not("[data-disabled]").prop("disabled", false).closest(".textfield-wrapper").removeClass("disabled");
                $this.find(":password").val("");
                var $btn = $this.find(".loader-on-submit");
                loader_remove($btn);
                $this.trigger("after-action-complete");
            },
            success: function (response) {
                if (response.popup) {
                    if (response.text) {
                        $.fancybox.close();
                        $.fancybox.open(response.text, $.fancybox.options_modal);
                    }
                }
                else {
                    var $message = $this.find(".js-message");
                    if (!$message.length) {
                        $message = $this.find($this.data("message"));
                    }
                    if (!$message.length) {
                        $message = $($this.data("message"));
                    }
                    $message.find(".js-message-container").html(response.text);
                    $message.addClass("active");
                    $this.addClass("inactive");
                    if ($this.data("message-autoclose"))
                    {
                        setTimeout(function(){
                            $message.removeClass("active");
                            $this.removeClass("inactive");
                        }, $this.data("message-autoclose"));
                    }
                }
                $this.trigger("after-action-success", response);
            },
            error: function (jqXHR) {
                form_process_errors($this, jqXHR);
                $this.trigger("after-action-error");
            }
        });
    });

    $(document).on("after-action-success", ".js-action-reset-after", function(e){
        $(this)[0].reset();
    });
}

function form_process_errors($form, jqXHR) {
    console.log(jqXHR.status, jqXHR.responseJSON);
    var error_text;
    if (jqXHR.status === 401) {
        error_text = 'Access denied.';
    }
    else if (jqXHR.status === 422) {
        var errors = jqXHR.responseJSON;
        var index = 0;
        $.each(errors, function(field_name, field_errors){
            $field = $form.find("[name='" + field_name + "']");
            if (!index) {
                $field.trigger("focus");
            }
            $field.addClass("error");
            $.each(field_errors, function(error_index, error_text) {
                $field.parent().append("<div class='form__error form__error--"+error_index+"'>" + error_text + "</div>");
            });
            index++;
        });
    }
    else {
        error_text = 'Произошла ошибка. Попробуйте ещё раз.';
    }
    $form.trigger("form-error", jqXHR);
    alert(error_text);
}

function bind_widgets() {
    checkbox_plain_init();
    img_to_bg();
    slider_init();
    selectbox_init();
    validate_init();
    fancybox_init();
    lazy_load();
    gmap_load();
    mask_init();
}